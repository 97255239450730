import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "./AppConfig";
export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("../Components/Screens/LoginScreen")),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() => import("../Components/Screens/RegisterScreen")),
  },
];
 
export const protectedRoutes = [
  {
    key: "HomePage",
    path: `${APP_PREFIX_PATH}/home`,
    component: React.lazy(() => import("../Components/Screens/DashboardScreen")),
  },
  {
    key: "UploadPage",
    path: `${APP_PREFIX_PATH}/upload`,
    component: React.lazy(() => import("../Components/Screens/UploadScreen")),
  },
  {
    key: "PreviewPage",
    path: `${APP_PREFIX_PATH}/preview`,
    component: React.lazy(() => import("../Components/Screens/PreviewScreen")),
  },
  
  
  {
    key: "PreviewPage",
    path: `${APP_PREFIX_PATH}/tree-view`,
    component: React.lazy(() => import("../Components/Screens/DashboardScreen/treeview")),
  },
  {
    key: "PreviewPageComparison",
    path: `${APP_PREFIX_PATH}/preview-comparison`,
    component: React.lazy(() =>
      import("../Components/Screens/PreviewComparison")
    ),
  },
  {
    key: "DashboardPreviewPageComparison",
    path: `${APP_PREFIX_PATH}/dashboard-preview-comparison`,
    component: React.lazy(() =>
      import("../Components/Screens/DashboardPreviewComparison")
    ),
  },
  {
    key: "EditPage",
    path: `${APP_PREFIX_PATH}/edit`,
    component: React.lazy(() => import("../Components/Screens/EditScreen")),
  },
  {
    key: "VersionHistoryPage",
    path: `${APP_PREFIX_PATH}/version-history`,
    component: React.lazy(() => import("../Components/Screens/VersionHistoryScreen")),
  },
  
];
 
